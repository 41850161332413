import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #2b5876, #4e4376);
  min-height: 100vh;
  color: white;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
`;

const QuestionLabel = styled.label`
  font-size: 1.5em;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const QuestionInput = styled.input`
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  max-width: 600px;
  font-size: 1.2em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    font-size: 1em;
    padding: 8px;
  }
`;

const AnswerTextarea = styled.textarea`
  padding: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  max-width: 600px;
  font-size: 1.2em;
  min-height: 150px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1em;
    padding: 8px;
    min-height: 120px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: #4e4376;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #6c63ff;
  }
  &:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 8px 16px;
  }
`;

const Feedback = styled.p`
  margin-top: 20px;
  font-size: 1.2em;
  max-width: 600px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${spin} 2s linear infinite;
`;

export default function Market() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(true);
  const { user, setUser, type, setType } = useContext(UserContext);
  const navigate = useNavigate();
  if (!user) {
    setUser("Random User");
  }
  useEffect(() => {
    if (!user) {
      setUser("Random User");
    }

    const fetchQuestions = async () => {
      try {
        const questions = [];
        for (let i = 0; i < 10; i++) {
          const response = await axios.post('https://api.openai.com/v1/chat/completions', {
            model: 'gpt-3.5-turbo',
            messages: [
              { role: 'system', content: 'You are a helpful assistant.' },
              { role: 'user', content: 'Ask a random question related to marketing.' }
            ],
            max_tokens: 50,
            n: 1,
            stop: ['\n']
          }, {
            headers: {
              'Authorization': `Bearer open_ai_key`,
              'Content-Type': 'application/json'
            }
          });

          const generatedQuestion = response.data.choices[0].message.content.trim();
          questions.push(generatedQuestion);
        }
        setQuestions(questions);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, [user, setUser]);

  const checkAnswer = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    const userAnswer = userAnswers[currentQuestionIndex] || '';

    try {
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          { role: 'user', content: `Is the following answer correct for the question "${currentQuestion}"? ${userAnswer}. Rate it out of 10` }
        ],
        max_tokens: 50,
        n: 1,
        stop: ['\n']
      }, {
        headers: {
          'Authorization': `Bearer open_ai_key`,
          'Content-Type': 'application/json'
        }
      });

      const generatedFeedback = response.data.choices[0].message.content.trim();
      setFeedback(generatedFeedback);
    } catch (error) {
      console.error('Error checking answer:', error);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setType("Marketing");

    try {
      const res = await axios.post('http://localhost:3001/sendDetails', { user, questions, userAnswers, feedback, type });
      if (res.data === "sent") {
        alert("Test submitted Succesfully");
        navigate('/');
      } else {
        alert("Error occurred, please try again later");
      }
    } catch (err) {
      alert("Error occurred, please try again later");
      console.error(err);
    }
  };

  const handleAnswerChange = (e) => {
    setUserAnswers({
      ...userAnswers,
      [currentQuestionIndex]: e.target.value
    });
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setFeedback('');
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setFeedback('');
    }
  };

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        questions.length > 0 && (
          <>
            <form onSubmit={submit}>
              <QuestionLabel htmlFor="input">Question {currentQuestionIndex + 1}</QuestionLabel>
              <QuestionInput id='input' type="text" value={questions[currentQuestionIndex]} readOnly />
              <AnswerTextarea 
                placeholder='Your answer here' 
                value={userAnswers[currentQuestionIndex] || ''}
                onChange={handleAnswerChange}
              />
              
              <ButtonContainer>
                <Button type="button" onClick={handleBack} disabled={currentQuestionIndex === 0}>Back</Button>
                <Button type="button" onClick={handleNext} disabled={currentQuestionIndex === questions.length - 1}>Next</Button>
                <Button type="button" onClick={checkAnswer}>Check</Button>
                <Button type="submit">Submit</Button>
              </ButtonContainer>
              
              {feedback && <Feedback>{feedback}</Feedback>}
            </form>
          </>
        )
      )}
    </Container>
  );
}
