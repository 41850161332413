import './App.css';
import Signup from './Signup';
import {Routes,Route} from 'react-router-dom'
import Home from './Home';
import Login from './Login';
import Software from './Dash/Software';
import Dashboard from './Dashboard';
import Hr from './Dash/Hr';
import Market from './Dash/Market';
import DataScience from './Dash/DataScience'
import Sales from'./Dash/Sales';
import Pm from './Dash/Pm';
import Report from './Report';
import Voice from './Voice'
import Design from './Dash/Design';
import LiveBot from './components/LiveBot/liveBot';
import ParentComponent from './components/ParentComponent';
import { UserProvider } from './context/UserContext'; 
import { SpeechToTextProvider } from './context/SpeechToTextContext';
import { SpeechRecoProvider } from './context/SpeechRecoContext';
import { TextToSpeechProvider } from './context/TextToSpeechContext';
import { SubjectSelectionProvider } from './context/SubjectSelectionContext';
import React from 'react';

function App() {
  return (
    <>
    <UserProvider>
    <TextToSpeechProvider>
        <SpeechToTextProvider>
          <SubjectSelectionProvider>
          {/* <SpeechRecoProvider> */}
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login/>} />
            <Route path='/dashboard' element={<Dashboard />}>
                <Route path='software' element={<Software />} />
                <Route path='market' element={<Market />} />
                <Route path='hr' element={<Hr />} />
                <Route path='ds' element={<DataScience />} />
                <Route path='sales' element={<Sales />} />
                <Route path='pm' element={<Pm />} />
                <Route path='design' element={<Design />} />
            </Route>
            <Route path='/signup' element={<Signup />} />
            <Route path='/reports' element={<Report />} />
            <Route path='/voice' element={<Voice />} />
            <Route path='/livebot' element={<ParentComponent/>} />
          </Routes>
          {/* </SpeechRecoProvider> */}
          </SubjectSelectionProvider>
        </SpeechToTextProvider>
        </TextToSpeechProvider>
    </UserProvider>
    </>
  );
}

export default App;
