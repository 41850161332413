import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// Format the remaining time as MM:SS
const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
const GptCard = ({time}) => {
  // console.log('gptCard', time);
  
    return (
        <Card sx={{
            width: 800,
            justifyContent: 'center',
            background: 'linear-gradient(135deg, #000000 0%, #800080 100%)', // Gradient from black to purple
            color: 'white', // White text for contrast
            borderRadius: '12px', // Rounded corners
            boxShadow: '0 6px 25px rgba(0, 0, 0, 0.7)', // Dark shadow for depth
            padding: '20px', // Additional padding
            position: 'relative', // Ensures content is well-positioned
            overflow: 'hidden' // Clean look by hiding overflow
          }}>
            {time > 1000 ? <CardContent>
              <Typography
                gutterBottom
                sx={{
                  fontSize: 14,
                  color: 'rgba(255, 255, 255, 0.9)', // Lighter white for secondary text
                  textTransform: 'uppercase', // Uppercase for formality
                  letterSpacing: '1px', // Slightly spaced out letters
                }}
              >
                Interview
              </Typography>
              <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                Interview Ends In: {formatTime(time)}
              </Typography>
              <Typography sx={{ color: 'rgba(255, 255, 255, 0.8)', mb: 1.5 }}>
                All The Best!!
              </Typography>
              <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                "All the best !!"
              </Typography>
            </CardContent> : <CardContent>
              <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                Interview Has Been Over !!
              </Typography>
              </CardContent>}
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button size="small" sx={{
                color: '#ffffff', // White button text
                backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent white background
                backdropFilter: 'blur(8px)', // Subtle blur effect for button
                borderRadius: '20px', // Rounded button
                padding: '5px 15px', // Additional padding for button
                textTransform: 'none', // Normal case text for button
                transition: 'background-color 0.3s', // Smooth transition for hover
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.3)', // Slightly more opaque on hover
                }
              }}>
                Learn More
              </Button>
            </CardActions>
          </Card>
      );
}

export default GptCard;
