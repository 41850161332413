import React, { Component } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Webcam from 'react-webcam';
import { loadModels, getFullFaceDescription } from '../FaceRecognition/face';

const WIDTH = 100;
const HEIGHT = 120;
const inputSize = 160;

class VideoInput extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();
    this.state = {
      detections: null,
      descriptors: null,
      referenceDescriptor: null,
      match: null,
      facingMode: 'user',
      referenceImage: null,
      noFaceFound: false,
      noFaceInReference: false,
      recording: false,
      recordedChunks: [],
      videoUrl: null, // For downloading the recorded video
      isWebcamReady: false, // State to track when webcam is ready
    };
    this.interval = null;
    this.mediaRecorder = null; // MediaRecorder instance
  }

  async componentDidMount() {
    try {
      await loadModels();
      this.setInputDevice();
    } catch (error) {
      console.error('Error loading models:', error);
    }
  }

  componentDidUpdate(prevProps) {
    // Only call start/stop recording when remainingTime changes and webcam is ready
    if (prevProps.remainingTime !== this.props.remainingTime && this.state.isWebcamReady) {
      if (this.props.remainingTime > 1000 && !this.state.recording) {
        this.startRecording();
      } else if (this.props.remainingTime <= 1000 && this.state.recording) {
        this.stopRecording();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
    }
  }

  setInputDevice = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter(device => device.kind === 'videoinput');

      if (videoInputDevices.length < 2) {
        this.setState({ facingMode: 'user' });
      } else {
        this.setState({ facingMode: { exact: 'environment' } });
      }

      this.startCapture();
    } catch (error) {
      console.error('Error setting input device:', error);
    }
  };

  startCapture = () => {
    // Capture frames every 1.5 seconds for face detection purposes
    this.interval = setInterval(this.captureFrame, 1500);
  };

  handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const imageSrc = e.target.result;
      this.setState({ referenceImage: imageSrc });

      try {
        const fullDesc = await getFullFaceDescription(imageSrc, inputSize);
        if (fullDesc && fullDesc.length > 0) {
          this.setState({
            referenceDescriptor: fullDesc[0].descriptor,
            noFaceInReference: false, // Face found
          });
          console.log('Reference face descriptor loaded successfully.');
        } else {
          this.setState({ noFaceInReference: true }); // No face found
          console.log('No face detected in the uploaded image.');
        }
      } catch (error) {
        console.error('Error processing reference image:', error);
      }
    };

    reader.readAsDataURL(file);
  };

  // Capture frame from webcam feed for face detection, not for video recording
  captureFrame = async () => {
    if (this.webcam.current) {
      const screenshot = this.webcam.current.getScreenshot();
      if (screenshot) {
        try {
          const fullDesc = await getFullFaceDescription(screenshot, inputSize);
          if (fullDesc && fullDesc.length > 0) {
            const detections = fullDesc.map(fd => fd.detection);
            const descriptors = fullDesc.map(fd => fd.descriptor);
            this.setState({
              detections,
              descriptors,
              noFaceFound: false, // Face found
            }, this.matchFaces);
          } else {
            this.setState({ noFaceFound: true }); // No face found in the webcam feed
            console.log('No face detected in the webcam feed.');
          }
        } catch (error) {
          console.error('Error capturing or processing screenshot:', error);
        }
      }
    }
  };

  matchFaces = () => {
    const { descriptors, referenceDescriptor } = this.state;
    if (descriptors && referenceDescriptor) {
      const matches = descriptors.map(descriptor => {
        const distance = this.calculateEuclideanDistance(descriptor, referenceDescriptor);
        return distance < 0.6;  // Adjust threshold if needed
      });
      console.log('Matching result:', matches);
      this.setState({ match: matches });
    } else {
      console.log('Descriptors or reference descriptor missing.');
    }
  };

  calculateEuclideanDistance = (d1, d2) => {
    if (!d1 || !d2) return Infinity; // In case of missing descriptors
    let sum = 0.0;
    for (let i = 0; i < d1.length; i++) {
      sum += (d1[i] - d2[i]) ** 2;
    }
    return Math.sqrt(sum);
  };

  // Start recording video
  startRecording = () => {
    if (this.webcam.current && this.webcam.current.video && this.webcam.current.video.srcObject instanceof MediaStream) {
      const stream = this.webcam.current.video.srcObject;
      
      console.log("Stream for MediaRecorder: ", stream);

      // Check for supported MIME types
      let mimeType = 'video/webm';
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        mimeType = 'video/webm;codecs=vp9';
      }
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        mimeType = 'video/mp4'; // Fallback to mp4 if webm is not supported
      }

      console.log("Using MIME type: ", mimeType);

      this.mediaRecorder = new MediaRecorder(stream, { mimeType });

      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.onstop = this.handleStopRecording;
      this.mediaRecorder.onerror = (e) => console.error('Recording error: ', e);
      this.mediaRecorder.start();

      console.log('Recording started');
      this.setState({ recording: true });
    } else {
      console.error('Webcam video not ready yet or no stream available');
    }
  };

  stopRecording = () => {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      console.log('Recording stopped');
      this.setState({ recording: false });
    }
  };

  handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      console.log('Recording data available');
      this.setState(prevState => ({
        recordedChunks: [...prevState.recordedChunks, event.data]
      }));
    } else {
      console.warn('No data available from MediaRecorder');
    }
  };

  handleStopRecording = () => {
    const { recordedChunks } = this.state;

    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: this.mediaRecorder.mimeType });

      const videoUrl = URL.createObjectURL(blob);
      this.setState({ videoUrl, recordedChunks: [] }); // Clear chunks after processing
      console.log('Video available at:', videoUrl);
    } else {
      console.error('No recorded chunks available');
    }
  };

  downloadVideo = () => {
    const { videoUrl } = this.state;
    if (videoUrl) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = videoUrl;
      a.download = 'face_detection_video.webm';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(videoUrl);
      console.log('Video downloaded');
    }
  };

  // Set webcam ready when metadata is loaded
  handleWebcamReady = () => {
    this.setState({ isWebcamReady: true });
    console.log('Webcam is ready');
  };

  render() {
    const { detections, match, facingMode, referenceImage, noFaceFound, noFaceInReference, videoUrl } = this.state;
    const videoConstraints = {
      width: WIDTH,
      height: HEIGHT,
      facingMode: facingMode,
    };

    return (
      <div className="Camera" style={{ display: 'flex', flexDirection: 'column', float: 'left' }}>
        <p>Camera: {facingMode === 'user' ? 'Front' : 'Back'}</p>
        
        <div style={{ marginBottom: '20px' }}>
          <input type="file" accept="image/*" onChange={this.handleImageUpload} />
          {referenceImage && (
            <div style={{ marginTop: '10px' }}>
              <p>Reference Image:</p>
              <img src={referenceImage} alt="Reference" width="100" />
              {noFaceInReference && (
                <p style={{ color: 'red' }}>No face detected in the reference image.</p>
              )}
            </div>
          )}
        </div>

        <div style={{ width: WIDTH, height: HEIGHT }}>
          <div style={{ position: 'relative', width: WIDTH }}>
            <Webcam
              audio={false}
              width={WIDTH}
              height={HEIGHT}
              ref={this.webcam}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              onLoadedMetadata={this.handleWebcamReady}
            />
            {noFaceFound ? (
              <p style={{ color: 'red' }}>No face detected in the webcam feed.</p>
            ) : (
              detections && detections.map((detection, i) => {
                const { _x, _y, _width, _height } = detection.box;
                return (
                  <div key={i} style={{
                    position: 'absolute',
                    border: 'solid 2px blue',
                    left: _x,
                    top: _y,
                    width: _width,
                    height: _height,
                  }}>
                    {match && match[i] !== undefined && (
                      <div style={{
                        backgroundColor: match[i] ? 'green' : 'red',
                        color: 'white',
                        position: 'absolute',
                        transform: `translateY(${_height}px)`,
                      }}>
                        {match[i] ? 'Match' : 'No Match'}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>

        {(videoUrl && this.props.remainingTime < 1000) && this.downloadVideo()}
      </div>
    );
  }
}

// Higher Order Component is replaced by using the hooks directly inside a functional wrapper
const VideoInputWithRouter = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  return <VideoInput navigate={navigate} location={location} params={params} {...props} />;
};

export default VideoInputWithRouter;
