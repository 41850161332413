import React, { useEffect, useState } from 'react';
import SubjectSelectionModal from './modals/SubjectSelectionModal';
import LiveBot from './LiveBot/liveBot';
const ParentComponent = () => {
  const [modalShow, setModalShow] = useState(true); // Modal is shown by default
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if modal is submitted

  useEffect(() => {
    // Trigger the modal on page load
    setModalShow(true);
  }, []);

  // Function to handle closing the modal
  const handleModalClose = () => {
    setModalShow(false); // Close the modal and allow the page to load further
  };
   // This function is called when the modal is submitted
   const handleModalSubmit = () => {
    setIsSubmitted(true);
    setModalShow(false);
  };

  return (
    <>
      {/* Show the modal */}{
        console.log('submit', isSubmitted)
        
      }
      <SubjectSelectionModal show={modalShow} onHide={handleModalClose} onSubmit={handleModalSubmit}/>

      {/* Conditionally render page content only when the modal is closed */}
      {isSubmitted && (
        <>
          <LiveBot />
        </>
      )}
    </>
  );
};

export default ParentComponent;
