import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from './context/UserContext';
const Dashboard = () => {
  
  const {user} = useContext(UserContext);
  if(!user){
    alert("You are continuing without signing in, your results will not be stored");
  }
  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <h2 style={styles.heading}>Career Options</h2>
        <ul style={styles.list}>
          <Link to='/dashboard/software' style={styles.link}><li style={styles.listItem}>Software Engineering</li></Link>
          <Link to='/dashboard/market' style={styles.link}><li style={styles.listItem}>Marketing</li></Link>
          <Link to='/dashboard/sales' style={styles.link}><li style={styles.listItem}>Sales</li></Link>
          <Link to='/dashboard/ds' style={styles.link}><li style={styles.listItem}>Data Science</li></Link>
          <Link to='/dashboard/pm' style={styles.link}><li style={styles.listItem}>Product Management</li></Link>
          <Link to='/dashboard/design' style={styles.link}><li style={styles.listItem}>Design</li></Link>
          <Link to='/dashboard/hr' style={styles.link}><li style={styles.listItem}>HR</li></Link>
        </ul>
      </div>
      <div style={styles.mainContent}>
        <Outlet />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
  },
  sidebar: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '200px',
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    boxSizing: 'border-box',
    overflowY: 'auto',
  },
  heading: {
    fontSize: '1.5em',
    marginBottom: '20px',
    borderBottom: '1px solid #555',
    paddingBottom: '10px',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    marginBottom: '15px',
    cursor: 'pointer',
    transition: 'color 0.3s',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  mainContent: {
    marginLeft: '200px',
    padding: '20px',
    width: 'calc(100% - 200px)',
  },
};

export default Dashboard;
