import { createContext, useState } from "react";

const SubjectSelectionContext = createContext("");

export const SubjectSelectionProvider = ({children})=>{
    const [subject, setSubject] = useState("");
    const [timer, setTimer] = useState("");
    const [experience, setExperience] = useState("");
    return (
        <SubjectSelectionContext.Provider value={{subject, setSubject, timer, setTimer, experience, setExperience}}>
            {children}
        </SubjectSelectionContext.Provider>
    );
};

export default SubjectSelectionContext;
