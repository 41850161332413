import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import html2pdf from 'html2pdf.js';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Report = ({onClickButton,result}) => {
  const match = result.includes("I'm sorry");
    const [open, setOpen] = React.useState(false);
   // Create a reference for the hidden content

  // Function to handle PDF download
  const downloadPDF = () => {
    // Create a temporary div element to hold the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = result; // Insert the HTML content into the temp div

    const options = {
      margin:       0.5, // Set margin to 0 to use all available space
      filename:     'my-InterviewAI-report.pdf',
      image:        { type: 'jpeg', quality: 0.95 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    
    // Usage example
    html2pdf().from(tempDiv).set(options).save();
    

    // Clean up: remove the temp div after PDF generation
    tempDiv.remove();
}
    const handleClickOpen = () => {
      setOpen(true);
      onClickButton();
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const navigate = useNavigate();

    const goToHomePage = () => {
      navigate('/');
    };
  
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Get Your report
      </Button>
      {result && <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Report
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: result }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
         {!match ? <Button onClick={downloadPDF}>Download PDF</Button> : <Button onClick={goToHomePage}>Retake</Button>} 
        </DialogActions>
      </Dialog>}
    </React.Fragment>
  );
}

export default Report;
