import React, { useState } from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
export default function Report() {
  const [result, setResult] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const showtime = () => {
    setLoading(true);
    axios.get('http://localhost:3001/result')
      .then((res) => {
        if (res.data === "Error") {
          setError("Cannot fetch results");
        } else {
          setResult(res.data);
        }
      })
      .catch(() => {
        setError("Cannot fetch results Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handle = ()=>{
    navigate('/');
  }
  return (
    <div style={styles.container}>
      <div style={{display:'flex',justifyContent:'space-around'}}>

      <button className='btn btn-dark' onClick={handle}>Back</button>
      <button className='btn btn-dark' onClick={showtime}>Show Results</button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p style={styles.error}>{error}</p>}

      {result.length > 0 && (
        <div style={styles.resultsContainer}>
          {result.map((test, index) => (
            <div key={index} style={styles.resultItem}>
              <h2 style={styles.testType}>Test Type: {test.testType}</h2>
              <h3>Mail: {test.mail}</h3>
              
              <h4>Questions:</h4>
              <ul>
                {test.questions.map((question, i) => (
                  <li key={i}>{question}</li>
                ))}
              </ul>

              <h4>Answers:</h4>
              <ul>
                {test.answers.map((answer, i) => (
                  <li key={i}>{answer}</li>
                ))}
              </ul>

              <h4>Feedback:</h4>
              <ul>
                {test.feedback.map((feedback, i) => (
                  <li key={i}>{feedback}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  resultsContainer: {
    marginTop: '20px',
  },
  resultItem: {
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  },
  testType: {
    fontSize: '1.5em',
    marginBottom: '10px',
  },
  error: {
    color: 'red',
  },
};
