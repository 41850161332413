import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import SubjectSelectionContext from '../../context/SubjectSelectionContext';

const SubjectSelectionModal = ({ show, onHide, onSubmit  }) => {
  // State to store input values
  const {subject, setSubject} = useContext(SubjectSelectionContext);
  const {timer, setTimer} = useContext(SubjectSelectionContext);
  const {experience, setExperience} = useContext(SubjectSelectionContext);
  // const [subjectError, setSubjectError] = useState('');
  // Function to handle form submission
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    // Close the modal after submission if needed1
    onSubmit();
    onHide();
  };

  return (
    <Modal
      size="nm"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Set Your Interview!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Form.Group controlId="formSubject">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formTimer" className="mt-3">
            <Form.Label>Timer (in minutes)</Form.Label>
            <Form.Control 
            required
              type="number"
              placeholder="Enter timer"
              value={timer}
              onChange={(e) => setTimer(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formExperienceLevel" className="mt-3">
            <Form.Label>Experience Level</Form.Label>
            <Form.Control 
            required
              as="select"
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
            >
              <option value="">Select experience level</option>
              <option value="fresher">Fresher</option>
              <option value="junior">Junior</option>
              <option value="senior">Senior</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubjectSelectionModal;
