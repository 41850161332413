import React, { useContext, useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextToSpeech from '../Speech/textToSpeech';
import SpeechToText from '../Speech/SpeechToText';
import SpeechToTextContext from '../../context/SpeechToTextContext';
import SubjectSelectionContext from '../../context/SubjectSelectionContext';
import GptCard from '../Card/GptCard';
import Report from '../Card/Report';
// import TabGuardComponent from '../TabGuardComponent';
import FaceDetectionWithRouter from './FaceDetection';
// import Visualizer from '../AudioVisualizer/Visualizer';
// import { isRouteErrorResponse } from 'react-router-dom';
// import { AudioInputStream } from 'microsoft-cognitiveservices-speech-sdk';
// import VideoRecorder from './VideoRecorder';

// import { breadcrumbsClasses } from '@mui/material';
const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
const serviceRegion = process.env.REACT_APP_SERVICE_REGION;

const LiveBot = () => {
    const [messages, setMessages] = useState([]);
    const [response, setResponse] = useState('');
    const [remainingTime, setRemainingTime] = useState(null); // Timer state
    const { text } = useContext(SpeechToTextContext);
    const [condition, setCondition] = useState('');
    const [result, setResult] = useState('');
    const {subject, timer, experience} = useContext(SubjectSelectionContext);
    const INTERVIEW_DURATION = (60 * timer) * 1000; // 2 minutes in milliseconds
    const [shouldStop, setShouldStop] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [checked, setChecked] = useState(false);
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    // const [isRecording, setIsRecording] = useState(false);
    

    let timerInterval;
    const startInterviewTimer = () => {
        
        if (!isTimerRunning) return; // Don't start the timer if it should not run
        let startTime = localStorage.getItem('interviewStartTime');
        if (!startTime) {
            startTime = Date.now();
            localStorage.setItem('interviewStartTime', startTime);
        }
        
        const updateTimer = () => {
            const elapsedTime = Date.now() - startTime;
            const timeLeft = INTERVIEW_DURATION - elapsedTime;
            
            if (timeLeft >= 0) {
                setRemainingTime(timeLeft);  
            } 
        };

        updateTimer(); // Initial call to set remaining time
        timerInterval = setInterval(updateTimer, 1000); // Update every second
        return () => clearInterval(timerInterval); // Clear interval on unmount
    };

    const handleTimeLimitReached = () => {
        setShouldStop(true);
        localStorage.removeItem('interviewStartTime');

        let chatHistory = JSON.parse(localStorage.getItem('messages'));
        
        // Check if the last object has the role "assistant"
        if (chatHistory != null && chatHistory.length > 2 && chatHistory[chatHistory.length - 1].role === "assistant") {
            // Remove the last object
            chatHistory.pop();
        }
        chatHistory = JSON.stringify(chatHistory);
        if (chatHistory) {
            const newPrompt = [
                { role: 'system', content: `You will act as an evaluation bot designed to assess the capabilities of a candidate based solely on the provided chat history. Follow these guidelines:

1. **Evaluation Scope**:
    - **Content**: Include all unique questions asked and the corresponding answers provided by the candidate in the evaluation.
    - **Strictly Based on Chat History**: The evaluation must be strictly based on the provided chat history. Do not use any external information, prior knowledge, or assumptions beyond what is contained within the chat history.
    - **Duplicate Questions**: If the same question is asked multiple times, evaluate only the most relevant or comprehensive answer provided by the candidate.
    - **Grammatical Errors**: Ignore any grammatical errors in the candidate's responses. Do not reduce the score due to such errors. Auto-correct the responses for clarity without altering the original intent.

2. **Scoring Criteria**:
    - **Score per Answer**: Assign a score out of 10 for each answer.
        - **Incorrect Answers**: If the candidate provides an incorrect answer, assign a score of **0**.
        - **No Answer Provided**: If the candidate did not provide an answer to a question, assign a score of **0** and note that no answer was given.
        - **Vagueness**: Do not penalize the candidate for vague responses. Focus solely on the correctness and relevance of the answer.
    - **Overall Score**: Calculate the total score based on individual answer scores.

3. **Feedback Report**:
    - **Format**: Generate a detailed feedback report in HTML.
    - **Table Specifications**:
        - **Colorful Design**: Use a colorful table that is clearly visible against a white background.
        - **Font Size**: Utilize smaller fonts within the table to ensure content fits neatly.
        - **Responsiveness**: Ensure the report is responsive and displays well on various devices and screen sizes.
    - **Content Structure**:
        - **Table Columns**:
            1. **Question**: List each unique question asked during the interview.
            2. **Candidate's Answer**: Display the candidate's provided answer, auto-corrected for grammatical clarity. If no answer was provided, indicate "No answer provided."
            3. **Score (Out of 10)**: Show the score assigned to each answer.
            4. **Reason for Score**: Explain why the particular score was given.
            5. **Improvement Suggestions**: Offer recommendations for how the candidate can improve their answers.
    - **Overall Summary**:
        - **Hiring Recommendation**: Provide a summary indicating whether the candidate should be hired.
        - **Training Needs**: Mention if the candidate requires additional training or improvement in specific areas.

4. **Additional Guidelines**:
    - **Ignore Grammatical Errors**: Do not let grammatical mistakes affect the scoring. Focus on the content and correctness of the answers.
    - **Auto-Correction**: Automatically correct grammatical errors in the candidate's responses to ensure clarity.
    - **Handling Incorrect Answers**: Assign a score of **0** for any answer that is incorrect, regardless of grammatical quality.
    - **Vagueness**: Do not expect 100% accuracy. Be lenient with vague answers as responses are provided by humans, not machines.
    - **Duplicate Questions**: When the same question is asked multiple times, evaluate only the most relevant or comprehensive answer provided by the candidate in the chat history to avoid redundancy and ensure fairness.
    - **Strict Adherence to Chat History**: Ensure that all evaluations, scores, and feedback are derived exclusively from the provided chat history. Do not incorporate any external information or assumptions.
    - **Handling Unanswered Questions**: If a question in the chat history does not have a corresponding answer from the candidate, mark the "Candidate's Answer" as "No answer provided," assign a score of **0**, and provide a reason indicating that no response was given.
` },
                { role: "user", content: chatHistory }
            ];
            localStorage.removeItem('messages');
            getChatResponse(newPrompt, 'evaluation');
            setShowLoader(true);
        }
    };
    const getChatResponse = async (messageContent, context) => {
        // newly addded
        let socket = null;
        const systemPrompt = {
            role: 'system',
            content:`You will act as an interview bot named Aurora, designed to assess the capabilities of an interviewee in ${subject}. Follow these guidelines:

1. **Introduction**: Start by introducing yourself as "Aurora, the InterviewAI Bot." State that you will be conducting a technical interview focused on ${subject}.

                    2. **Question Format**: Ask concise, relevant questions tailored to a ${experience}-level position. Each question should be under 30 words.

                    3. **Interactive Session**:
                        - **Acknowledgment**: After each interviewee response, acknowledge their answer with a brief and courteous statement."
                        - **Conversational Flow**: Maintain a natural and engaging dialogue to make the interview feel more personable and less robotic.

                    4. **Question Flow**: Ask only one question at a time. If a deep dive is necessary, base your next question on the interviewee's previous response.

                    5. **No Feedback**: Do not assess, comment on, or provide feedback on the answers. Another agent will handle that. Your sole role is to ask questions.

                    6. **Focus on Questions**: You are permitted to ask follow-up questions that probe deeper into the interviewee's understanding. However, refrain from offering any explanations or additional commentary.

                    **Example Flow**:
                    - **Aurora**: "Hello, I am Aurora, the InterviewAI Bot. I will be conducting a technical interview focused on ${subject}. Let's begin."
                    - **Aurora**: "Can you explain the core principles of ${subject}?"
                    - *(After interviewee responds)*
                    - **Aurora**: "Thank you for your explanation. Could you provide an example of how you've applied these principles in a project?"
                    - *(After interviewee responds)*
                    - **Aurora**: "I appreciate your detailed response. Let's move on to the next question."

`
            // content: `You will act as an interview bot named Aurora, designed to assess the capabilities of an interviewee in ${subject}. Follow these guidelines:
            // 1. Introduction: Start by introducing yourself as "Aurora, the InterviewAI Bot." State that you will be conducting a technical interview focused on ${subject}.
            // 2. Question Format: Ask concise, relevant questions tailored to a ${experience}-level position. Each question should be under 30 words.
            // 3. Question Flow: Ask only one question at a time. If a deep dive is necessary, base your next question on the interviewee's previous response.
            // 4. No Feedback: Do not assess, comment on, or provide feedback on the answers. Another agent will handle that. Your sole role is to ask questions.
            // 5. Focus on Questions: You are permitted to ask follow-up questions that probe deeper into the interviewee's understanding. However, refrain from offering any explanations or additional commentary.`
        };
        let messagesToSend;
        
        // newly addded
        if (context === 'conversation') {
            if (messageContent) {
                messagesToSend = [systemPrompt, ...messages, { role: 'user', content: messageContent }];
            } else {
                messagesToSend = [systemPrompt, ...messages];
            }
        } else if (context === 'evaluation') {
            messagesToSend = [systemPrompt, ...messageContent];  // In case of evaluation, messageContent is an array of messages
        } else {
            messagesToSend = [systemPrompt];
        }
        try {
        if (!socket || socket.readyState === WebSocket.CLOSED) {
            const socket = new WebSocket('ws://127.0.0.1:8000/ws/livebot/');
            // const socket = new WebSocket('wss://49b4-2401-4900-1f3e-665d-5130-20cc-693-140a.ngrok-free.app/ws/livebot/');
            socket.onmessage = function(event) {
                const data = JSON.parse(event.data);
                // console.log('from gpt:',data);
                
                if (data.context === 'conversation') {
                    // const responseTime = Date.now();
                    // const timeTaken = responseTime - socket.sendTime;
                    // console.log('timeTaken',timeTaken);
                    // console.log(data.response);
                    
                    setResponse(data.response);
                    saveMessages(messageContent,data.response);
                } 
                if (data.context === 'evaluation') {
                    setResult(data.response);
                }
            };
            
            function sendMessage(messageContent, context) {
                if (socket.readyState === WebSocket.OPEN) {
                    // socket.sendTime = Date.now();
                    socket.send(JSON.stringify({
                        'text': messageContent,
                        'context': context
                    }));
                }else {
                    console.error("WebSocket is not open, unable to send message.");
                }
            }
            socket.onopen = function(event) {
                console.log("WebSocket is open now.");
                sendMessage(messagesToSend, context);

            };
    }
    } catch (error) {
        console.error("Error fetching response from OpenAI:", error);
    }
    };
    const saveMessages = (userMessage, gptResponse) => {
        const newMessages = [
            ...messages,
            { role: 'user', content: userMessage },
            { role: 'assistant', content: gptResponse }
        ];
        setMessages(newMessages);
        localStorage.setItem('messages', JSON.stringify(newMessages));
    };

    useEffect(() => {
        console.log('starting interview timer');
        if (isTimerRunning) {
            
            startInterviewTimer();
            getChatResponse('', "conversation");
        }
        return () => {
            clearInterval(timerInterval); // Ensure interval is cleared on unmount
        };
    }, [isTimerRunning]); // Only run this effect when isTimerRunning changes
    
    useEffect(() => {
        if (text) {
            setCondition(true);
        }
    }, [text]);

    useEffect(() => {
        if (condition && text) {
            getChatResponse(text, "conversation");
            setCondition(false);
        }
    }, [condition, text]);

    
    const handleChange = (event) => {
        setChecked(event.target.checked);
      };
    
    const finishInterview = ()=>{
        clearInterval(timerInterval); // Clear the timer interval
        localStorage.removeItem('interviewStartTime');
        setRemainingTime(0);
        setIsTimerRunning(false); // Set the flag to stop the timer from restarting
    }  
    return (
        <>
        
     <div>
       {remainingTime !== 0 && <FaceDetectionWithRouter remainingTime={remainingTime} />} 
     </div>
    <div className='container'> 
     <div id="out"></div>
        {/* {remainingTime > 1000 && <TabGuardComponent finishInterview={finishInterview}/>}  */}
            <div  style={{textAlign:"center",display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                <GptCard time={remainingTime}/>
            </div>
            <div>
                {remainingTime > 1000 && <TextToSpeech subscriptionKey={subscriptionKey} serviceRegion={serviceRegion} text={response} shouldStop={shouldStop}/>}
                {remainingTime > 1000 && <SpeechToText time={remainingTime} /> }
                {remainingTime > 1000 && <span style={{textAlign:"center",display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                Toggle it to control bot's dialog's visibility: 
                <Switch  checked={checked}  onChange={handleChange} color="secondary" />
                </span> }
                {(checked && remainingTime > 1000)&& <p style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent black background
                                color: "white",                        // White text
                                padding: "10px",                       // Padding around the text
                                borderRadius: "5px",                   // Rounded corners
                                width: "fit-content",                  // Width adjusts to content
                                position: "absolute",                  // Positioning for overlay
                                top: "150px",                        // Distance from the bottom of the container
                                left: "50%",                           // Center horizontally
                                transform: "translateX(-50%)",         // Adjust position to truly center
                                zIndex: 1000  }}>{response}</p>}
            </div>
        </div>
           
        <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                }}>
            {(!result && showLoader) && <Stack sx={{ color: 'grey.500' }} spacing={50} direction="row">
            <CircularProgress color="secondary" />
            </Stack>}
           {remainingTime > 1000 && <Button onClick={finishInterview} variant="contained" color="error" >
                    Finish
                </Button>} 
            {remainingTime < 1000 && <Report onClickButton = {handleTimeLimitReached} result = {result}/>}
        </div>
        {/* <div><Visualizer/></div> */}
        </>
    );
};

export default LiveBot;
