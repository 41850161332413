import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from './context/UserContext';
import axios from 'axios'
export default function Login() {
    const [isChecked, setIsChecked] = useState(false);
  const { setUser } = useContext(UserContext); // Correctly access setUser from context
  const [mail, setMail] = useState('');
  const [password, setPass] = useState('');
  const navigate = useNavigate();
  

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUser(mail);
    axios.post('http://localhost:3001/login',{mail,password}).then((res)=>{
        if(res.data === "Login successful"){
            alert("You are Logged In !!");
            navigate('/');
        }
        else if(res.data === "Incorrect password"){
            alert("Incorrect credentials");
            navigate('/login');
        }
        else{
            alert("User not found");
            navigate('/signup');
        }
    }).catch((res)=>{
        if(res.data === "Incorrect password"){
            alert("Incorrect credentials");
            navigate('/login');
        }
        else{
            alert("User not found");
            navigate('/signup');
        }
    })
    
  };

  return (
    <div style={styles.container}>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="exampleInputEmail1" style={styles.label}>Email address</label>
          <input
            type="email"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            style={styles.input}
            required
          />
          <div id="emailHelp" style={styles.helpText}>We'll never share your email with anyone else.</div>
        </div>
        
        <div style={styles.formGroup}>
          <label htmlFor="exampleInputPassword1" style={styles.label}>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPass(e.target.value)}
            required
            className="form-control"
            id="exampleInputPassword1"
            style={styles.input}
          />
        </div>
        <div style={styles.formCheck}>
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            required
            style={{
              ...styles.checkbox,
              boxShadow: isChecked ? '0 0 10px white' : 'none',
              transform: isChecked ? 'scale(1.1)' : 'scale(1)'
            }}
            onChange={handleCheckboxChange}
          />
          <label className="form-check-label" htmlFor="exampleCheck1" style={styles.checkboxLabel}>
            I agree to the terms and conditions
          </label>
        </div>
        <button type="submit" style={styles.button}>Submit</button>
      </form>
    </div>
  )
}

const styles = {
    container: {
      padding: '100px 20px',
      maxWidth: '400px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: '5px',
      fontWeight: 'bold',
      color:'white'
    },
    input: {
      padding: '10px',
      fontSize: '1em',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    helpText: {
      marginTop: '5px',
      fontSize: '0.9em',
      color: '#6c757d',
    },
    formCheck: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    checkbox: {
      width: '20px',
      height: '20px',
      cursor: 'pointer',
      backgroundColor:'black',
      transition: 'all 0.3s ease',
    },
    checkboxLabel: {
      fontSize: '0.9em',
      cursor: 'pointer',
      color:'grey'
    },
    button: {
      padding: '10px 20px',
      fontSize: '1em',
      backgroundColor: 'white',
      color: 'black',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
  };
  
  // Media query for responsiveness
  const mediaQueryStyles = `
  @media (max-width: 600px) {
    .form-control {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
  `;
  
  // Inject media query styles into the document head
  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerText = mediaQueryStyles;
  document.head.appendChild(styleSheet);
  