import React, { useContext, useState, useEffect, useRef } from 'react';
import Switch from '@mui/material/Switch';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import SpeechToTextContext from '../../context/SpeechToTextContext';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
const serviceRegion = process.env.REACT_APP_SERVICE_REGION;

const SpeechToText = ({ time }) => {
    const { settext } = useContext(SpeechToTextContext);
    const [interimTranscript, setInterimTranscript] = useState("");
    const [finalTranscript, setFinalTranscript] = useState("");
    const [remainingTime, setRemainingTime] = useState(30);
    const timerRef = useRef(null);
    const recognizerRef = useRef(null);
    const intervalRef = useRef(null);
    const [checked, setChecked] = useState(false);
    const [isListening, setIsListening] = useState(false); // State to track listening status

    useEffect(() => {
        if (isListening) {
            handleStartListening();
        } else {
            handleStopListening();
        }
    }, [isListening]); // Run when isListening changes

    const startTimer = (updatedFinalTranscript) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setRemainingTime(30);
        console.log('remaining time', remainingTime);
        
        timerRef.current = setTimeout(() => {

            if (updatedFinalTranscript) {
                settext(updatedFinalTranscript);
                setFinalTranscript("");
                setInterimTranscript("");
                console.log("Submitting text after 30 seconds:", updatedFinalTranscript);
            }
            clearInterval(intervalRef.current);
        }, 30000);

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime > 0) return prevTime - 1;
                clearInterval(intervalRef.current);
                return 0;
            });
        }, 1000);
    };

    const resetTimer = (updatedFinalTranscript) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        startTimer(updatedFinalTranscript);
    };

    const handleStartListening = () => {
        setInterimTranscript("");
        setFinalTranscript("");

        const speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
        speechConfig.speechRecognitionLanguage = 'en-US';
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

        recognizer.recognizing = (s, e) => {
            setInterimTranscript(e.result.text);
        };

        recognizer.recognized = (s, e) => {
            if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                setFinalTranscript(prevTranscript => {
                    const updatedTranscript = prevTranscript + " " + e.result.text;
                    resetTimer(updatedTranscript);
                    return updatedTranscript;
                });
            }
        };

        recognizer.canceled = (s, e) => {
            if (e.reason === sdk.CancellationReason.Error) {
                console.error(`CANCELED: ErrorDetails=${e.errorDetails}`);
                if (e.errorDetails.includes("Quota exceeded")) {
                    alert("Speech recognition quota exceeded. Please try again later or upgrade your subscription.");
                }
            }
            recognizer.stopContinuousRecognitionAsync();
        };

        recognizer.sessionStopped = (s, e) => {
            recognizer.stopContinuousRecognitionAsync();
        };

        recognizer.startContinuousRecognitionAsync();
        recognizerRef.current = recognizer;
    };

    const handleStopListening = () => {
        if (recognizerRef.current) {
            recognizerRef.current.stopContinuousRecognitionAsync(() => {
                console.log(finalTranscript);
                setRemainingTime(0);
                settext(finalTranscript);
                setFinalTranscript("")
                setInterimTranscript("");
                console.log("Stopped listening.");
            });
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
            clearInterval(intervalRef.current);
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const toggleListening = () => {
        setIsListening(prevState => !prevState);
    };

    return (
        <div style={{ textAlign: "center", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {time > 1000 && <div>
                <p style={{ color: "#393736" }}>
                    Your Answer will appear here:
                </p>
                {/* {(recognizerRef.current && finalTranscript && time > 1000) && (
                    <p style={{ color: "grey" }}>Submitting your answer in : {remainingTime} seconds</p>
                )} */}
                <textarea
                    name="myInput"
                    value={finalTranscript}
                    readOnly
                    style={{
                        width: '100%',
                        minHeight: '150px',
                        padding: '15px',
                        borderRadius: '10px',
                        border: 'none',
                        outline: 'none',
                        background: 'linear-gradient(135deg, #000000, #800080)',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: "'Roboto', sans-serif",
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
                        resize: 'none',
                    }}
                />
                Toggle it to control your dialog's visibility:
                <Switch checked={checked} onChange={handleChange} color="secondary" />
            </div>}

            {(checked && time > 1000) && (
                <div>
                    <p style={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        padding: "10px",
                        borderRadius: "5px",
                        width: "fit-content",
                        position: "absolute",
                        bottom: "20px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 1000
                    }}>{interimTranscript}</p>
                </div>
            )}

            {time > 1000 && <div style={{marginLeft:'50px'}}> 
              <IconButton
                    variant="contained"
                    color="primary"
                    onClick={toggleListening}
                    style={{
                        backgroundColor: isListening ? '#FF1744' : '#00C853',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '50%',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.3s ease'
                    }}
                >
                    {isListening ? <MicOffIcon /> : <MicIcon />}
                </IconButton>
            </div>}
        
</div>
    );
};

export default SpeechToText;
