import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from './context/UserContext';
import { Link } from 'react-router-dom';
export default function Navbar() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const handleNavClick = () => {
    if (user) {
      setUser(null);
    } else {
      navigate('/signup');
    }
  };

  return (
    <div style={styles.navbar}>
      <div style={styles.logo}>
        Interview Management System
      </div>
      <div className="navItems" style={styles.navItems}>
        <Link to='/voice' style={styles.navLink}>AI Interview</Link>
        <Link to="/dashboard" style={styles.navLink}>Dashboard</Link>
        <Link to="/reports" style={styles.navLink}>Reports</Link>
        <Link to="/livebot" style={styles.navLink}>Live Bot</Link>
      </div>
      <button onClick={handleNavClick} style={styles.button}>
        {user ? 'Logout' : 'Signup/Login'}
      </button>
    </div>
  );
}

const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px 20px',
    color: 'white',
    flexWrap:'wrap',
    borderStyle: 'solid',
    borderColor: 'white',
    borderRadius: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    backdropFilter: 'blur(10px)', // Blurs the background
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  logo: {
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  navItems: {
    display: 'flex',
    gap: '10px',
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '1em',
    transition: 'color 0.3s',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    backgroundColor: 'white',
    color: 'black',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

// Add media query for responsiveness
const mediaQueryStyles = `
@media (max-width: 768px) {
  .navItems {
    display: none;
  }
  .button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
  .logo {
    font-size: 1.2em;
  }
}
@media (min-width: 769px) {
  .navItems {
    display: flex;
  }
}
`;

// Inject media query styles into the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = mediaQueryStyles;
document.head.appendChild(styleSheet);
