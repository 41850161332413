import { createContext, useState } from "react";

const TextToSpeechContext = createContext("");

export const TextToSpeechProvider = ({children})=>{
    const [isSpeaking, setIsSpeaking] = useState(true);
    return (
        <TextToSpeechContext.Provider value={{isSpeaking, setIsSpeaking}}>
            {children}
        </TextToSpeechContext.Provider>
    );
};

export default TextToSpeechContext;
