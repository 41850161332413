import { createContext, useState } from "react";

const SpeechToTextContext = createContext("");

export const SpeechToTextProvider = ({children})=>{
    const [text, settext] = useState("");
    return (
        <SpeechToTextContext.Provider value={{text, settext}}>
            {children}
        </SpeechToTextContext.Provider>
    );
};

export default SpeechToTextContext;