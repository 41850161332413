import React from 'react';
import Navbar from './Navbar';

export default function Home() {
  return (
    <>
    
    <div style={styles.container}>
      <Navbar />
      <div style={styles.content}>
        <div style={styles.textContainer}>
          <h1 style={styles.title}>AI  for more human hiring experiences ! </h1>
          <span style={styles.subtitle}>
            Automate 90% of interview management tasks, unlock data-driven insights, and deliver exceptional experiences with human-centric AI
          </span>
        </div>
        <div style={styles.imageContainer}>
          <img
            src="https://www.transparentpng.com/thumb/interview/interview-wonderful-picture-images-1.png"
            alt="Interview"
            style={styles.image}
          />
        </div>
      </div>
    </div>
    {/* component two starts */}
    <div style={{
    height: '50vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(https://www.hdwallpapers.in/download/colorful_geometric_shapes_squares_black_background_hd_abstract-HD.jpg)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
    color: 'white',
}}>
    <h2 style={{ fontSize: '2.5em', margin: '0 0 10px', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)' }}>
        What is Human Centric AI?
    </h2>
    <span style={{
        fontSize: '1.2em',
        maxWidth: '800px',
        lineHeight: '1.5',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
        margin: '0 auto'
    }}>
        Our AI doesn’t replace human abilities, but enhances them. By automating the tedious parts of the hiring process, we free you up to focus on the stuff machines can’t mimic — genuine human connection.
        <br /><br />
        Elevate your hiring strategy, 10x your talent team’s efficiency, and ensure that every interview is more than a meeting — it’s a meaningful experience that locks in top talent.
    </span>
</div>
<br /><br /><br /><br />
{/* component 3 starts */}
    <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'wrap',alignItems:'center'}}>
        <div id='detail' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'orange'}}>
            <h1 style={{fontWeight:'900'}}>50%</h1>
            <h4>reduction in time-to-fill</h4>
        </div>
        <div id='detail' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'orange'}}>
            <h1 style={{fontWeight:'900'}}>88%</h1>
            <h4>reduction in time spent answering</h4>
        </div>
        <div id='detail' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'orange'}}>
            <h1 style={{fontWeight:'900'}}>5X</h1>
            <h4>interview experience</h4>
        </div>    
    </div>
    {/* component 4 starts */}
    <div style={{
    height: '50vh',
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundImage: 'url(https://www.hdwallpapers.in/download/colorful_geometric_shapes_squares_black_background_hd_abstract-HD.jpg)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
    color: 'white',
}}>
    <h2 style={{ fontSize: '2.5em', margin: '0 0 10px', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)' }}>
    Elevate your entire hiring journey without sacrificing the human touch
    </h2>
    <span style={{
        fontSize: '1.2em',
        maxWidth: '800px',
        lineHeight: '1.5',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
        margin: '0 auto'
    }}>
        In this management system, the candidate has to select the domain of the interview (ex- Software Engg, Marketing,etc), and then for each question,the candidate has to answer the question via speech, this speech is simultaneously converted to text in front of candidate. Now depending on the answers, at the end a detailed report of the scores is given to the candidate.
    </span>
</div>

    </>
  );
}

const styles = {
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '60px', // To prevent content from being hidden behind the navbar
    padding: '20px',
    boxSizing: 'border-box',
    
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1200px',
    padding: '20px',
    boxSizing: 'border-box',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Optional background for contrast
    borderRadius: '10px', // Rounded corners
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)', // Subtle shadow
  },
  title: {
    fontSize: '2.5em',
    marginBottom: '20px',
    color: '#fff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)', // Enhanced shadow
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '1.2em',
    lineHeight: '1.6',
    color: '#fff',
    textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)', // Enhanced shadow
    maxWidth: '80%', // Limits width for better readability
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '400px',
  },
};

// Add media query for responsiveness
const mediaQueryStyles = `
@media (max-width: 768px) {
  .textContainer {
    maxWidth: 100%;
    textAlign: center;
  }
  .imageContainer {
    maxWidth: 100%;
  }
  .title {
    fontSize: 2em;
  }
  .subtitle {
    fontSize: 1em;
  }
}
@media (max-width: 480px) {
  .title {
    fontSize: 1.5em;
  }
  .subtitle {
    fontSize: 0.9em;
  }
}
`;

// Inject media query styles into the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = mediaQueryStyles;
document.head.appendChild(styleSheet);
