import React, { useEffect, useRef } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

const TextToSpeech = ({ text, subscriptionKey, serviceRegion, shouldStop }) => {
  // console.log('stop',shouldStop);
  
  const synthesizerRef = useRef(null); // Ref to hold the synthesizer instance

  useEffect(() => {
    if (synthesizerRef.current) {
      // Ensure that the previous synthesizer is not disposed multiple times
      try {
        synthesizerRef.current.close();
      } catch (error) {
        console.warn("Synthesizer is already closed or disposed.");
      }
    }

    if (!shouldStop) {
      handleSynthesize(text);
    }

    return () => {
      if (synthesizerRef.current) {
        try {
          synthesizerRef.current.close();
        } catch (error) {
          console.warn("Synthesizer was already closed or disposed.");
        }
      }
    };
  }, [text, shouldStop]);

  useEffect(() => {
    if (shouldStop && synthesizerRef.current) {
      handleStopSpeaking();
    }
  }, [shouldStop]);

  const handleSynthesize = (text) => {
    const speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
    speechConfig.speechSynthesisVoiceName = "en-US-AvaMultilingualNeural";
    const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);

    synthesizerRef.current = synthesizer; // Save the synthesizer instance in ref

    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          console.log("Synthesis finished.");
        } else {
          console.error(
            "Speech synthesis canceled, " +
            result.errorDetails +
            "\nDid you set the speech resource key and region values?"
          );
        }
        synthesizerRef.current = null; // Set ref to null after closing
        synthesizer.close();
      },
      (err) => {
        console.trace("err - " + err);
        synthesizerRef.current = null; // Set ref to null after closing
        synthesizer.close();
      }
    );
  };

  const handleStopSpeaking = () => {
    if (synthesizerRef.current && typeof synthesizerRef.current.stopSpeakingAsync === 'function') {
      synthesizerRef.current.stopSpeakingAsync(() => {
        console.log("Speech stopped.");
        synthesizerRef.current.close();
        synthesizerRef.current = null;
      }, (err) => {
        console.error("Error stopping the speech:", err);
      });
    } else {
      console.warn("synthesizerRef.current is not initialized or stopSpeakingAsync is not available.");
    }
  };

  return (
    <div>
      {/* You can add additional UI here to display status, such as 'Speaking...' */}
    </div>
  );
};

export default TextToSpeech;
